.StepRangeSlider {
  position: relative;
  width: 100%;
  padding: 5px 2px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.StepRangeSlider__track {
  height: 5px;
  width: 100%;
  border-radius: 4px;
  background-color: #bbb;
  border: 1px solid #999;
  box-sizing: border-box;;
  /*-webkit-appearance: slider-horizontal;*/
}

.StepRangeSlider__handle {
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-50%,-50%,0);
}

.StepRangeSlider__thumb {
  border-radius: 10px;
  width: 14px;
  height: 14px;
  border: 1px solid #888;
  background-color: #eee;
  /*-webkit-appearance: sliderthumb-horizontal;*/
}

.StepRangeSlider__thumb:active {
  background-color: #ddd;
}

.StepRangeSlider__tooltip {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
}
